import React from "react"
import lowfye50black from "../images/RedLowfye50.jpg"
import bandPic from "../images/trench_pic.jpg"
import { Tour } from "../tour/Tour"
import "./LandingPage.css"
import { Bio } from "../Bio"

export const LandingPage = () => (
    <>
        <div className="logoContainer">
            <img src={lowfye50black} alt="logo" className="logo resize" />
        </div>
        <div className="logoContainer">
            <img src={bandPic} alt="bandPic" className="bandPic" />
        </div>
        <Bio />

        <h2 className="tourDateHeader">Pre-Save New Single These Horns</h2>

        <div className="solid"></div>
        
        <div  className="iconContainer">
            <div className="audioTrack preSaveLink">
                <iframe className="spotifySingleEpk" src="https://orcd.co/01gxklx" width="100%" height="380" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>
        </div>

        <Tour />
    </>
)